<template>
    <div class="separator">
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss">
.separator {
    margin-top: 6rem;
    width: 100%;
    height: 5px;
    background-color: #EBE9F1;
}
</style>
